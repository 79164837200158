import React, { useState, useEffect } from "react";
import Marquee from "react-marquee-slider";
import styled from "styled-components";
import "../Css/Clients.css";

import Akhil from "../Assets/Images/Clients/Akhil.png";
import EastCoastRailway from "../Assets/Images/Clients/EastCoastRailway.png";
import UNHU from "../Assets/Images/Clients/UNHU.png";
import Avetech from "../Assets/Images/Clients/Avetech.png";
import Beyond from "../Assets/Images/Clients/Beyond.png";
import MNS from "../Assets/Images/Clients/MNS.png";
import Omnis from "../Assets/Images/Clients/Omnis.png";
import Silicon from "../Assets/Images/Clients/Silicon.png";

const clientLogos = [
  { id: "1", img: Akhil },
  { id: "2", img: EastCoastRailway },
  { id: "3", img: UNHU },
  { id: "4", img: Avetech },
  { id: "5", img: Beyond },
  { id: "6", img: MNS },
  { id: "7", img: Omnis },
  { id: "8", img: Silicon },
];

// Styled component for individual logo
const LogoWrapper = styled.div`
  width: 380px;
  margin: 0 15px;
`;

const Clients = () => {
  const [speed, setSpeed] = useState(50);

  // Function to update velocity based on screen size
  const updateVelocity = () => {
    const width = window.innerWidth;
    if (width <= 768) setSpeed(20); // Slower for tablets
    else if (width <= 480) setSpeed(10); // Slowest for mobile
    else setSpeed(50);
  };

  // Apply velocity change on mount & screen resize
  useEffect(() => {
    updateVelocity();
    window.addEventListener("resize", updateVelocity);
    return () => window.removeEventListener("resize", updateVelocity);
  }, []);

  return (
    <div 
      className="carousel-pages"
      onMouseEnter={() => setSpeed(0)}
      onMouseLeave={updateVelocity}
    >
      <Marquee velocity={speed} direction="ltr" scatterRandomly={false} resetAfterTries={200}>
        {clientLogos.map((image, index) => (
          <LogoWrapper key={index}>
            <img src={image.img} alt={`client-${index}`} loading="lazy" style={{ width: "100%", height: "auto" }} />
          </LogoWrapper>
        ))}
      </Marquee>
    </div>
  );
};

export default Clients;
